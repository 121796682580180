import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

const AboutPage = lazy(() => import('../pages/AboutPage'));
const HomePage = lazy(() => import('../pages/HomePage'));
const ServicesPage = lazy(() => import('../pages/ServicesPage'));
const ProjectsPage = lazy(() => import('../pages/ProjectPage'));
const ClientsPage = lazy(() => import('../pages/ClientsPage'));
const ContactPage = lazy(() => import('../pages/ContactPage'));
const DevStrat = lazy(() => import('../subpages/DevStrat'));
const QualityEng = lazy(() => import('../subpages/QualityEng'));
const ProManagement = lazy(() => import('../subpages/ProManagement'));
const LeanManufacturing = lazy(() => import('../subpages/LeanManu'));
const HrPartnering = lazy(() => import('../subpages/HrPartnering'));
const ErrorPage = lazy(() => import('../pages/ErrorPage'));

const Page = () => {
    return (
        <>
            <Suspense fallback={<p>...</p>}>
                <Switch>
                    <Route path="/" exact component={HomePage} />
                    <Route path="/about" component={AboutPage} />
                    <Route path="/services" component={ServicesPage} />
                    <Route path="/projects" component={ProjectsPage} />
                    <Route path="/clients" component={ClientsPage} />
                    <Route path="/contact" component={ContactPage} />
                    <Route path="/development-strategy" component={DevStrat} />
                    <Route path="/quality-engineering" component={QualityEng} />
                    <Route path="/project-management" component={ProManagement} />
                    <Route path="/lean-manufacturing" component={LeanManufacturing} />
                    <Route path="/hr-partnering" component={HrPartnering} />
                    <Route component={ErrorPage} />
                </Switch>
            </Suspense>
        </>
    );
}



export default Page