import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Page from '../layouts/Page';

import '../css/styles.min.css';

const App = () => {

  return (
    <Router>
      <div className="App" id="outer-container">
        <section className="page">
          <Page />
        </section>
      </div>
    </Router>
  );
}

export default App;

